<template>
 <div>
   <div class="work-title bg-white margin-top padding">
     <div class="work-title-all flex justify-content-between aligin-items-center flex-wrap-wrap">
       <el-button type="primary" size="mini" @click="goNav('/educationCenter/newUpgrade')">新建升期</el-button>
       <div class="work-title-search">
         <el-input placeholder="请输入升期名称" v-model="stuInput" class="input-with-select" size="mini" style="width:100%;">
           <el-select v-model="stuSelect" slot="prepend" placeholder="升期名称">
             <el-option label="张龙" value="1" />
             <el-option label="陈欢" value="2" />
             <el-option label="周杰" value="3" />
             <el-option label="朱均" value="3" />
           </el-select>
           <el-button slot="append" icon="el-icon-search" />
         </el-input>
       </div>
     </div>
   </div>
   
   <!-- 列表 -->
   <div class="work-list margin-top bg-white">
     <as-list :initdataList="datalist">
       <el-table-column align="center" prop="orderId" sortable label="升期关系名称" />
       <el-table-column align="center" prop="name1" sortable label="期数" />
       <el-table-column align="center" prop="text4" sortable label="课程数" />
       <el-table-column align="center" prop="text4" sortable label="创建时间" />
       <el-table-column align="center" prop="text5" label="操作" />
     </as-list>
   </div>
 </div>
</template>

<script>
export default {
  data() {
    return {
      stuInput: '',
      stuSelect: '',
      datalist: []
    };
  },
  created() {},
  mounted() {},
  methods: {
    goNav(url) {
      this.$router.push(url);
    },
  }
};
</script>

<style scoped>
  .el-button--mini {
    border-radius: 4px !important;
    padding: 6px 15px !important;
  }
</style>

<template>
	<el-dialog :title="titles" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" :show-close='false' width="50%">
		<div style="height: 410px; ">
			<div style="text-align: center;">
				<el-upload class="upload-demo" drag action="#" multiple accept=".xls" @click="openFullScreen1"
					v-loading.fullscreen.lock="fullscreenLoading">
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">上传文件</div>
				</el-upload>
				<div class="margin-top-sm"><i class="iconfont icon-yuandianxiao line-blue"></i>点击<label>下载导入模板</label>’
				</div>
			</div>

			<div class="margin-top-xl border-top-grey padding-top-sm">
				<div style="text-align: center;">导入须知</div>
				<div class="margin-left-xl">
					<div>
						1、红字标题的列为必填;
					</div>
					<div>
						2、请不要增减列数、或修改标题；须知说明和3条示例数据无需删除、对导入数据操作无影响;
					</div>
					<div>
						3、为保证导入数据处理效率，建议每次最多导入200条
					</div>
				</div>
			</div>
			<div class="flex justify-content-flexEnd margin-top-lg">
				<el-button @click="close">关闭</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		props: {
			Visible: {
				type: Boolean,
				default: false,
			},
			titles:{
				type: String,
				default: '',
			}
		},
		data() {
			return {
				dialogVisible: false,
				fullscreenLoading: false,
			}
		},
		watch: {
			Visible(val) {
				this.dialogVisible = val;
			}
		},
		created() {
			this.dialogVisible = this.Visible;
		},
		methods: {
			close() {
				this.$emit('closepop')
			},
			openFullScreen1() {
				this.fullscreenLoading = true;
				setTimeout(() => {
					this.fullscreenLoading = false;
				}, 2000);
			},
		}
	}
</script>

<style>
</style>

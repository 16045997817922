<template>
	<div>
		<div class="work-title bg-white margin-top-sm padding">
			<div class="work-title-all flex justify-content-between aligin-items-center flex-wrap-wrap">
				<el-button type="primary" size="mini" @click="editHandle">新建科目</el-button>
				<div class="work-title-search">
					<el-input placeholder="请输入科目名称" v-model="listQuery.KeyWord" maxlength="50" class="input-with-select"
						size="small" style="width:200px;"></el-input>
				</div>
			</div>
		</div>

		<!-- 列表 -->
		<div class="work-list margin-top bg-white">
			<as-list ref="reload" :ajaxMethods="{ list: 'getDictionaryList' }" :listQueryProp="listQuery">
				<el-table-column prop="id" label="序号" align="center" width="150" />
				<el-table-column prop="title" label="科目名称" />
				<el-table-column align="center" prop="" label="操作" width="150">
					<template slot-scope="scope">
						<el-dropdown trigger="click" @command="item => handleCommand(item, scope.row)">
							<label class="cursor">
								操作
								<i class="el-icon-arrow-down el-icon-caret-bottom"></i>
							</label>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="e">编辑</el-dropdown-item>
								<el-dropdown-item command="d">删除</el-dropdown-item>
								<!-- <el-dropdown-item>停用</el-dropdown-item> -->
							</el-dropdown-menu>
						</el-dropdown>
					</template>
				</el-table-column>
			</as-list>
		</div>

		<el-dialog title="删除科目" :visible.sync="dialogVisible" width="30%">
			<span>删除后无法恢复，确定删除吗？</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="del">确 定</el-button>
			</span>
		</el-dialog>

		<newSubjects :Visible="editVisible" :title="title" :id="id" @closepop="closeEditPop"
			@getList="getSubjectTypeList" :formTitle="formTitle"></newSubjects>
	</div>
</template>

<script>
	import newSubjects from '../../components/newSubjects.vue';
	export default {
		components: {
			newSubjects
		},
		data() {
			return {
				id: 0,
				editVisible: false,
				dialogVisible: false,
				title: '新建科目',
				delData: {},
				formTitle: '',
				listQuery: {
					HasRole: false,
					module: 'SubjectType',
					KeyWord: ''
				}
			};
		},
		methods: {
			editHandle() {
				this.editVisible = true;
			},
			getSubjectTypeList() {
				this.$refs.reload.reload();
			},
			closeEditPop() {
				this.editVisible = false;
				this.listQuery = {
					HasRole: false,
					module: 'SubjectType',
					KeyWord: ''
				}
			},
			// 列表操作
			handleCommand(type, data) {
				this.id = data.id;
				if (type == 'e') {
					this.title = '编辑科目';
					this.formTitle = data.title;
					this.editVisible = true;
				} else {
					this.dialogVisible = true;
					this.delData = data;
				}
			},
			del() {
				let obj = {
					ids: this.delData.id,
					status: -99,
					module: this.delData.module
				};
				this.$Dictionary.ChangeDictionaryStatus(obj).then(() => {
					this.$message.success('删除成功');
					this.dialogVisible = false;
					this.listQuery = {
						HasRole: false,
						module: 'SubjectType',
						KeyWord: ''
					}
				});
			}
		}
	};
</script>

<style scoped>
	.el-button--mini {
		border-radius: 4px !important;
		padding: 6px 15px !important;
	}
</style>

<template>
  <el-dialog :title="title" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false" :close-on-press-escape="false" @closed="close" width="25%">
    <div style="height: 110px;">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="科目名称" prop="title">
          <el-input v-model="ruleForm.title" maxlength="50" @keyup.native="btKeyUp" @keydown.native="btKeyDown" placeholder="请填写科目名称,最多50个字,不能为特殊符号"></el-input>
        </el-form-item>
      </el-form>
      <div class="flex justify-content-flexEnd">
        <el-button type="primary" @click="submit">确定</el-button>
        <el-button class="myClient-left-30" @click="close">取消</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    Visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '新建科目'
    },
    id: {
      type: Number,
      default: 0
    },
    formTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialogVisible: false,
      groupNmae: '',
      ruleForm: {
        title: ''
      },
      rules: {
        title: [
          {
            required: true,
            message: '请输入项目名称',
            trigger: 'blur'
          },
          {
            max: 50,
            message: '最多50个字',
            trigger: 'blur'
          }
        ]
      }
    };
  },
  watch: {
    Visible(val) {
      this.dialogVisible = val;
    },
    formTitle(val) {
      this.ruleForm.title = val;
    }
  },
  created() {
    this.dialogVisible = this.Visible;
  },
  methods: {
    // 只能输入汉字、英文、数字
    btKeyDown(e) {
      e.target.value = e.target.value.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g, '');
    },
    //限制输入特殊字符
    btKeyUp(e) {
      e.target.value = e.target.value.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, '');
    },
    close() {
      this.$emit('closepop');
    },
    getList() {
      this.$emit('getList');
    },
    submit() {
      let ruleForm = this.ruleForm;
      if (this.title == '新建科目') {
        this.$Dictionary
          .AddDictionary({
            module: this.$Dictionary.moduleConfig.SubjectType,
            ...ruleForm
          })
          .then(() => {
            this.$message.success('添加成功');
            this.close();
          });
      } else {
        this.$Dictionary
          .EditDictionary({
            module: this.$Dictionary.moduleConfig.SubjectType,
            id: this.id,
            ...ruleForm
          })
          .then(() => {
            this.$message.success('修改成功');
            this.getList();
            this.close();
          });
      }
    }
  }
};
</script>

<style></style>

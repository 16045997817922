<template>
  <div class="">
    <div class="bg-z">
      <el-tabs v-model="activeName" type="card" style="min-height: 87vh;">
        <el-tab-pane disabled label="课程" name="first"></el-tab-pane>

        <el-tab-pane label="课程列表" name="two">
          <div class="work-title bg-white padding">
            <div>
              <el-button type="primary" size="mini" @click="goNav('/educationCenter/newCourse')">新建课程</el-button>
              <!-- <el-button size="mini" @click="editHandle(1)">批量导入</el-button> -->
            </div>
            <div class="margin-top-sm work-title-all flex justify-content-between aligin-items-center flex-wrap-wrap">
              <div class="work-title-content flex aligin-items-center">
                <el-popover width="200" trigger="click">
                  <!-- <el-input v-model="queryList.open_school" placeholder="" size="mini" /> -->
                  <el-checkbox-group v-model="queryList.open_school">
                    <el-checkbox class="padding-xs" :label="item.id" v-for="(item, index) of schoolList" :key="index">{{ item.name }}</el-checkbox>
                  </el-checkbox-group>
                  <div slot="reference">
                    <span>开课校区</span>
                    <i class="el-icon-caret-bottom" />
                    <i class="border-right" />
                  </div>
                </el-popover>

                <el-popover width="200" trigger="click">
                  <!-- <el-input v-model="queryList.curriculum_type" placeholder="" size="mini" /> -->
                  <el-checkbox-group v-model="queryList.curriculum_type">
                    <el-checkbox class="padding-xs" :label="item.id" v-for="(item, index) of courseClassList" :key="index">{{ item.title }}</el-checkbox>
                  </el-checkbox-group>
                  <div slot="reference">
                    <span>课程类别</span>
                    <i class="el-icon-caret-bottom" />
                    <i class="border-right" />
                  </div>
                </el-popover>

                <el-popover width="150" trigger="click">
                  <el-checkbox-group v-model="queryList.tmode">
                    <el-checkbox class="padding-xs" :label="0">班课</el-checkbox>
                    <el-checkbox class="padding-xs" :label="1">一对一</el-checkbox>
                  </el-checkbox-group>
                  <div slot="reference">
                    <span>授课模式</span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover>
                <el-popover width="150" trigger="click">
                  <el-checkbox-group v-model="queryList.charge_type">
                    <el-checkbox class="padding-xs" :label="0">按课时</el-checkbox>
                    <el-checkbox class="padding-xs" :label="2">按期</el-checkbox>
                    <el-checkbox class="padding-xs" :label="1">按时间</el-checkbox>
                  </el-checkbox-group>
                  <div slot="reference">
                    <span>收费模式</span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover>

                <el-popover width="150" trigger="click">
                  <el-checkbox-group v-model="queryList.status">
                    <el-checkbox class="padding-xs" :label="0">开售</el-checkbox>
                    <el-checkbox class="padding-xs" :label="1">停售</el-checkbox>
                  </el-checkbox-group>
                  <div slot="reference">
                    <span>课程状态</span>
                    <i class="el-icon-caret-bottom" />
                    <i class="border-right" />
                  </div>
                </el-popover>

                <el-popover width="200" trigger="click">
                  <el-checkbox-group v-model="queryList.curriculum_subject">
                    <el-checkbox class="padding-xs" :label="item.id" v-for="(item, index) of SubjectTypeList" :key="index">{{ item.title }}</el-checkbox>
                  </el-checkbox-group>
                  <div slot="reference">
                    <span>科目</span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover>
                <el-popover width="150" trigger="click">
                  <!-- <el-input v-model="classInput" placeholder="" size="mini" /> -->
                  <el-checkbox-group v-model="queryList.curriculumseason">
                    <el-checkbox class="padding-xs" :label="item.value" v-for="(item, index) of fourSeasons" :key="index">{{ item.label }}</el-checkbox>
                  </el-checkbox-group>
                  <div slot="reference">
                    <span>学季</span>
                    <i class="el-icon-caret-bottom" />
                  </div>
                </el-popover>
              </div>

              <div class="work-title-search flex">
                <el-input placeholder="请输入课程名称" v-model="queryList.KeyWord" class="input-with-select" size="mini" style="width:300px;"></el-input>
              </div>
            </div>

            <!-- <div class="work-title-status flex margin-top-sm aligin-items-center justify-content-between">
              <div class="flex aligin-items-center">
                <div class="text-gray flex aligin-items-center">
                  <div>课程状态：</div>
                  <div v-for="(btn, btnIndex) in curriculumSatus" :key="btnIndex">
                    <div :class="{ 'margin-left': btnIndex > 0 }">
                      <el-button size="mini">
                        {{ btn }}
                        <i class="el-icon-close" @click="delOderStatus(btnIndex)" />
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="text-blue cursor" @click="clearSelected">清空筛选</div>
            </div> -->
          </div>

          <!-- 列表 -->
          <div class="work-list margin-top bg-white">
            <div class="padding flex justify-content-between">
              <div class="flex">
                <!-- <el-button size="mini">批量导出</el-button> -->
                <!-- <el-button size="mini">批量编辑</el-button> -->
                <el-button size="mini" @click="selDel">批量删除</el-button>
                <el-button size="mini" @click="selStartSales">批量起售</el-button>
                <el-button size="mini" @click="selEndSales">批量停售</el-button>
              </div>
              <div class="flex">
                <el-popover width="150" trigger="click">
                  <el-checkbox-group v-model="selList">
                    <el-checkbox class="padding-xs" label="科目" />
                    <el-checkbox class="padding-xs" label="学季" />
                    <el-checkbox class="padding-xs" label="备注" />
                  </el-checkbox-group>
                  <el-button type="primary" size="mini" slot="reference">自定义显示列</el-button>
                </el-popover>
              </div>
            </div>
            <div class="padding border-tb">
              当前结果：共计
              <label v-text="outer_data.total || 0">0</label>
              个课程 |
              <label v-text="outer_data.open_count || 0">0</label>
              个课程正在开班/课
            </div>

            <as-list ref="reload" :ajaxMethods="{ list: 'getCurriculumList' }" :listQueryProp="queryList"  @requestChange="requestChange" @SelectionChange="selectAllChange">
              <el-table-column align="center" type="selection" width="45"></el-table-column>
              <el-table-column prop="listm" type="expand">
                <template slot-scope="scope">
                  <div v-for="item in scope.row.listm">
                    <!-- <span>校区 = {{ item.school_name }}</span> -->
                    <span class="margin-left" v-if="item.charge_type == 0">未知</span>
                    <span class="margin-left" v-if="item.charge_type == 1">按课时</span>
                    <span class="margin-left" v-if="item.charge_type == 2">按时间</span>
                    <span class="margin-left" v-if="item.charge_type == 3">按期</span>
                    <span class="margin-left">{{ item.num }}课时 = {{ item.money }}元</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="curriculum_name" sortable label="课程名称" width="280"></el-table-column>
              <el-table-column prop="curriculum_type_namme" label="课程类别" width="200" />
              <el-table-column align="center" prop="tmode" label="授课模式" width="85">
                <template slot-scope="scope">
                  <span v-if="scope.row.tmode == 0" class="class-tag">班</span>
                  <span v-if="scope.row.tmode == 1" class="">
                    <svg class="icon" aria-hidden="true"><use xlink:href="#icon-dui"></use></svg>
                  </span>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="openclasscount" label="开班数" width="70" />
              <el-table-column align="center" prop="charge_type" label="收费模式" width="85">
                <template slot-scope="scope">
                  <span v-if="scope.row.charge_type == 0">未知</span>
                  <span v-if="scope.row.charge_type == 1">按课时</span>
                  <span v-if="scope.row.charge_type == 2">按时间</span>
                  <span v-if="scope.row.charge_type == 3">按期</span>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="open_school" label="开课校区" width="140">
                <template slot-scope="scope">
                  <!-- <span v-if="scope.row.open_school == '[0]'">全校区</span> -->
                  <span class="text-cut" v-if="scope.row.school_name">{{ scope.row.school_name.join(',') }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="创建日期" width="130">
                <template slot-scope="scope">
                  {{ scope.row.add_time.slice(0, 10) }}
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip v-if="selList.indexOf('科目') > -1" prop="curriculum_subjectname" label="科目" />
              <el-table-column align="center" v-if="selList.indexOf('学季') > -1" prop="season" label="学季">
                <template slot-scope="scope">
                  <span v-if="scope.row.season == 0">春季</span>
                  <span v-if="scope.row.season == 1">夏季</span>
                  <span v-if="scope.row.season == 2">秋季</span>
                  <span v-if="scope.row.season == 3">冬季</span>
                </template>
              </el-table-column>
              <el-table-column v-if="selList.indexOf('备注') > -1" prop="remarks" label="备注" />
              <el-table-column align="center" prop="student_status" label="课程状态">
                <template slot-scope="scope">
                  <as-switch
                    @change="value => statusChange(value, scope.row.id)"
                    :value="0"
                    :activeValue="scope.row.status"
                    active-text="开售"
                    inactive-text="停售"
                    :height="25"
                  ></as-switch>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作" prop="">
                <template slot-scope="scope">
                  <el-dropdown trigger="click" @command="item => handleCommand(item, scope.row)">
                    <label>
                      操作
                      <i class="el-icon-arrow-down el-icon-caret-bottom"></i>
                    </label>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="edit">编辑课程</el-dropdown-item>
                      <!-- <el-dropdown-item command="copy">复制课程</el-dropdown-item> -->
                      <el-dropdown-item command="del">删除课程</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </as-list>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="升期关系列表" name="three">
          <sort-stage />
        </el-tab-pane> -->
        <el-tab-pane lazy label="课程类别" name="four"><course-class /></el-tab-pane>
        <el-tab-pane lazy label="科目设置" name="fives"><subject-type /></el-tab-pane>
      </el-tabs>
      <!-- 删除课程弹窗 -->
      <el-dialog title="删除课程" :visible.sync="delVisible" width="30%">
        <span>是否确认删除课程？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="delVisible = false">取 消</el-button>
          <el-button type="primary" @click="sureDelCourse">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 批量编辑弹窗 -->
      <!-- <el-dialog title="批量编辑" :visible.sync="editVisible" width="30%">
        <div>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="编辑内容" prop="region">
              <el-select v-model="ruleForm.region" placeholder="请选择">
                <el-option label="学季" value="shanghai"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="统一改为" prop="name"><el-input v-model="ruleForm.name"></el-input></el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="delVisible = false">取 消</el-button>
          <el-button type="primary" @click="sureDelCourse">确 定</el-button>
        </span>
      </el-dialog> -->
      <!-- 导入 -->
      <classImport :Visible="editVisible && editType === 1" titles="课程导入" @closepop="closeEditPop"></classImport>
    </div>
  </div>
</template>
<script>
import classImport from '../components/classImport.vue';
import { fourSeasons } from '@/config/index';
import SortStage from './child/sortStage.vue';
import CourseClass from './child/courseClass.vue';
import SubjectType from './child/subjectType.vue';
export default {
  components: {
    classImport,
    SortStage,
    CourseClass,
    SubjectType
  },
  data() {
    return {
      editType: 1,
      editVisible: false,
      fourSeasons,
      activeName: 'two',
      schoolList: [], // 经办校区
      courseClassList: [],
      SubjectTypeList: [],
      curriculumSatus: ['停售', '开售'], // 订单状态
      selList: [], // 自定义显示列
      queryList: {
        KeyWord: '',
        tmode: [],
        curriculumseason: [], // 学季
        open_school: [],
        curriculum_type: [], // 课程类别
        curriculum_subject: [], //  科目
        status: [], // 状态
        charge_type: []
      },
      listQuery1: {
        HasRole: false,
        module: '',
        PageIndex: 1,
        PageSize: 10
      },
      delVisible: false, //删除课程弹窗
      delCourseId: '',
      editForm: {},
      selectAllList: [],
      outer_data:{}
    };
  },
  created() {
    this.getSchoolList();
    this.getCourseClass();
    this.getSubjectTypeList();
  },
  mounted() {},
  methods: {
    requestChange(val){
      this.outer_data = val.outer_data || {};
      this.outer_data.total = val.total;
    },
    // 获取校区
    getSchoolList() {
      let obj = {
        type: 1,
        PageIndex: 1,
        PageSize: 10
      };
      this.API.OrganizationList(obj).then(res => {
        if (res.success) {
          this.schoolList = res.data.rows;
        }
      });
    },
    getCourseClass() {
      this.listQuery1.module = 'course_class';
      this.$Dictionary.GetDictionarys(this.listQuery1).then(res => {
        this.courseClassList = res.data.rows;
        this.$forceUpdate();
      });
    },
    getSubjectTypeList() {
      this.listQuery1.module = 'SubjectType';
      this.$Dictionary.GetDictionarys(this.listQuery1).then(res => {
        this.SubjectTypeList = res.data.rows;
        this.$forceUpdate();
      });
    },
    // 列表操作
    handleCommand(type, val) {
      if (type == 'del') {
        this.delVisible = true;
        this.delCourseId = val.id;
      } else if (type == 'edit') {
        this.$router.push({ name: 'editCourse', params: { id: val.id } });
      }
    },
    // 确认删除课程
    sureDelCourse() {
      this.API.updateCurriculumStatus(this.delCourseId, -99).then(res => {
        if (res.code == 200) {
          this.delVisible = false;
          this.$refs.reload.reload();
          this.$message.success('删除成功');
          this.delCourseId = ''
          this.selectAllList = []
        }
      });
    },
    statusChange(val, id) {
      let status = val == 1 ? 0 : 1;
      this.API.updateCurriculumStatus(id, status).then(res => {
        if (res.code == 200) this.$message.success(res.message);
      });
    },
    delOderStatus(index) {
      this.curriculumSatus.splice(index, index + 1);
    },
    // 多选
    selectAllChange(e) {
      // console.log(e);
      e.map(t => {
        this.selectAllList.push(t.id)
      })
      console.log(this.selectAllList);
    },
    // 批量删除
    selDel() {
      this.delCourseId = ''
      if(this.selectAllList.length > 0) {
        this.delVisible = true;
        this.delCourseId = this.selectAllList.join(',')
      }else {
        this.$message.error('请选择课程')
      }
    },
    // 批量起售
    selStartSales() {
      this.delCourseId = ''
      if(this.selectAllList.length > 0) {
        this.delCourseId = this.selectAllList.join(',')
        this.API.updateCurriculumStatus(this.delCourseId, 0).then(res => {
          if (res.code == 200) {
            this.$refs.reload.reload();
            this.$message.success('操作成功');
            this.delCourseId = ''
            this.selectAllList = []
          }
        });
      }else {
        this.$message.error('请选择课程')
      }
    },
    // 批量下架
    selEndSales() {
      this.delCourseId = ''
      if(this.selectAllList.length > 0) {
        this.delCourseId = this.selectAllList.join(',')
        this.API.updateCurriculumStatus(this.delCourseId, 1).then(res => {
          if (res.code == 200) {
            this.$refs.reload.reload();
            this.$message.success('操作成功');
            this.delCourseId = ''
            this.selectAllList = []
          }
        });
      }else {
        this.$message.error('请选择课程')
      }
    },
    goNav(url) {
      this.$router.push(url);
    },
    editHandle(type) {
      this.editVisible = true;
      this.editType = type;
    },
    clearSelected() {
      this.queryList = {
        KeyWord: '',
        tmode: [],
        curriculumseason: [], // 学季
        open_school: [],
        curriculum_type: [], // 课程类别
        curriculum_subject: [], //  科目
        status: [], // 状态
        charge_type: []
      };
    }
  }
};
</script>
<style scoped>
.el-input__inner {
  width: 220px !important;
}

>>> .el-select .el-input {
  width: 110px !important;
}

.el-button--mini {
  border-radius: 4px !important;
  padding: 6px 15px !important;
}
.icon {
  width: 25px;
  height: 25px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
